.entity-actions-dropdown {
  .dropdown-menu {
    min-width: 200px;

    .dropdown-menu-item-icon {
      margin-right: var(--spacing-s);
    }

    .entity__action-item {
      display: flex;
      flex: 1;
    }
  }

  &.use-browser-tabs {
    .dropdown-menu {
      background-color: var(--background-color-primary);
      /**
      * fixes open in New Tab option text & shortcut in WindowOS.
      * https://postmanlabs.atlassian.net/browse/APPUX-153
      */
      min-width: 250px;
      .dropdown-menu-item-shortcut {
        margin-left: var(--spacing-l);
      }
    }
  }
}

div.entity-actions-dropdown-menu-listV2 {
  ul.entity-actions-dropdown-menu-listV2 {
    box-sizing: border-box;
    width: 186px;
  }
}

.entity__action-itemV2 {
  display: flex;
  flex: 1;
  align-items: center;
  gap: var(--spacing-s);
}

.entity-actions-dropdown-menu-buttonV2 {
  height: 24px;
  width: 24px;


  &:hover {
    background-color: var(--highlight-background-color-tertiary);
  }
}
